import { bootstrapApplication } from '@angular/platform-browser';
import { initEffects } from '@ngneat/effects';
import { enableElfProdMode } from '@ngneat/elf';
import * as Sentry from '@sentry/angular-ivy';

import { EssGtagHelper } from '@ess/shared/utils/helpers';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

initEffects();
if (environment.production) {
  enableElfProdMode();

  Sentry.init({
    dsn: environment.envVar.SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: environment.envVar.ENV,
    // TODO add proper version from image once versioning is implemented
    release: '0.0.0',

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 1.0,

    tracePropagationTargets: [environment.envVar.BACKEND_URL],

    // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  if (environment.envVar.ENV === 'production') {
    EssGtagHelper.initConfig('G-41FEGEWLHY', { send_page_view: false });
  }
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
