export const environment = {
  production: true,
  envVar: {
    APP_VERSION: '0.0.0',
      BACKEND_URL: "https://is-esp-4qqhnlmesq-ew.a.run.app/api/v1/",
      OKTA_ISSUER: "https://wpp.okta.com",
      OKTA_CLIENT_ID: "0oa4vy3253tvxsgQB417",
      OKTA_REDIRECT_URI: "https://isd.groupm.com/login/callback",
      SENTRY_DSN: "https://2e4e291c1a8a80790c36dad2642f62e1@sentry.octra-staging.online/3",
      SENTRY_AUTH_TOKEN: "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",
      SENTRY_URL: "https://sentry.octra-staging.online",
      ENV: "production"
},
};
