import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { SharedUiLoadingSpinnerComponent } from '@ess/shared/ui/loading';
import { EssGtagHelper } from '@ess/shared/utils/helpers';

@Component({
  standalone: true,
  selector: 'ess-is',
  template: `
    <div class="w-full h-full overflow-hidden bg-mat-background relative">
      <div class="absolute top-0 left-0 w-full z-0 flex-center h-full">
        <ess-shared-ui-loading-spinner> Loading Integrated Search Dashboard... </ess-shared-ui-loading-spinner>
      </div>
      <div class="w-full h-full relative">
        <router-outlet />
      </div>
    </div>
  `,
  imports: [RouterOutlet, SharedUiLoadingSpinnerComponent],
})
export class AppComponent {
  constructor(private readonly __router: Router) {
    this.__router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        EssGtagHelper.sendEvent('page_view', {
          page_location: document.location.origin + event.urlAfterRedirects,
          page_title: document.title,
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
}
