import { inject, Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { combineLatest, take } from 'rxjs';

import { IntegratedSearchClientsDataAccessClientsFacade } from '@ess/integrated-search/clients/data-access';
import { IntegratedSearchProjectsDataAccessFacade } from '@ess/integrated-search/projects/data-access';
import { IntegratedSearchSharedFeatureTitleService } from '@ess/integrated-search/shared/feature';

@Injectable()
export class AppTitleStrategy extends TitleStrategy {
  private readonly __titleService = inject(IntegratedSearchSharedFeatureTitleService);
  private readonly __clientsFacade = inject(IntegratedSearchClientsDataAccessClientsFacade);
  private readonly __projectsFacade = inject(IntegratedSearchProjectsDataAccessFacade);

  updateTitle(snapshot: RouterStateSnapshot): void {
    const routeTitle = this.buildTitle(snapshot);

    combineLatest([this.__clientsFacade.activeClient$, this.__projectsFacade.activeProject$])
      .pipe(take(1))
      .subscribe(([client, project]) => this.__titleService.setTitle(routeTitle, client?.name, project?.project_name));
  }
}
