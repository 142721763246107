import { Routes } from '@angular/router';

import { IS_AUTH_GUARD, IS_GUEST_GUARD } from '@ess/integrated-search/auth/data-access';
import { ISRoutesParts } from '@ess/integrated-search/shared/utils';

import { environment } from '../environments/environment';

export const ROUTES: Routes = [
  {
    path: ISRoutesParts.login,
    loadComponent: () =>
      import('@ess/integrated-search/auth/feature/login').then((c) => c.IntegratedSearchAuthFeatureLoginComponent),
    canMatch: [IS_GUEST_GUARD],
    title: 'Log In',
  },
  {
    path: ISRoutesParts.public,
    loadChildren: () => import('@ess/integrated-search/public').then((r) => r.IS_PUBLIC_ROUTES(environment.envVar.ENV)),
  },
  {
    path: '',
    loadChildren: () => import('@ess/integrated-search/layout').then((r) => r.IS_LAYOUT_ROUTES),
    canMatch: [IS_AUTH_GUARD],
  },
  { path: '**', redirectTo: '' },
];
